import React from 'react';
import { config, useSpring } from 'react-spring';
import Layout from '../components/layout';
import { AnimatedBox } from '../elements';
import SEO from '../components/SEO';

const Page404 = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout>
      <SEO title="Not found | Agência LAVA" desc="A Agência LAVA é especializada em Web Design e Web Development" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>🤔 Não achamos o que você está procurando...</h1>
      </AnimatedBox>
    </Layout>
  );
};

export default Page404;
